import * as React from 'react';
import { IApiCategoryData } from './api-category.data';
import { IApiCategoryProps } from './api-category.props.autogenerated';
// @ts-ignore
import Slider from 'react-slick';
export type GridSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface IApiTestViewProps extends IApiCategoryProps<IApiCategoryData> {
    categories: string;
    isMobileView: boolean;
}

export interface IApiCategoryState {
    isMobile: boolean;
}

class ApiTest extends React.PureComponent<IApiCategoryProps<IApiCategoryData>, IApiCategoryState> {
    private readonly localyOnlineRecordId = 5637144702;

    constructor(props: IApiCategoryProps<IApiCategoryData>) {
        super(props);
    }
    public render(): JSX.Element | null {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: true,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        infinite: true,
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        centerPadding: '60px'
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                        centerPadding: '60px'
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };
        const {
            data: { categories }
        } = this.props;
        var categoriesList = [];
        if (categories && categories.result) {
            //remove this to remove from category carousel
            const ramadanOfferCategory = {
                image_url: 'https://images-eu-prod.cms.commerce.dynamics.com/cms/api/vnnhvggtsx/imageFileData/MD3jwG?pubver=6',
                link: '/localy-deals/5637160326.c',
                name: 'Localy Deals'
            };
            categoriesList.push(ramadanOfferCategory);
            //remove this to remove from category carousel
            const parentCategory = categories.result.find(category => category.RecordId === this.localyOnlineRecordId);
            if (parentCategory && parentCategory.Children.length > 0) {
                const base_image_url = 'https://images-eu-prod.cms.commerce.dynamics.com/cms/api/vnnhvggtsx/imageFileData/'; //process.env.MSDyn365Commerce_CMSIMAGEBASEURL;
                // const base_url = process.env.MSDyn365Commerce_LOCALYBASEURL;
                for (let i = 0; i < parentCategory.Children.length; i++) {
                    if (parentCategory.Children[i].ParentCategory === this.localyOnlineRecordId) {
                        // @ts-ignore
                        const image = parentCategory.Children[i].Images[0].Uri;
                        const url = image
                            ? `${base_image_url}search?fileName=/${image}&w=230&h=180&m=6&cropfocalregion=true`
                            : `${base_image_url}MDfBO?pubver=1`;
                        let name = `${parentCategory.Children[i].Name}`;
                        name = name.toLowerCase();

                        const link = `/${name.replace(/\s+/g, '-').toLowerCase()}/${parentCategory.Children[i].RecordId}.c`;
                        // parentCategory.Children[i].Children.length > 0
                        //     ? `/${parentCategory.Children[i].Children[0].Name}/${parentCategory.Children[i].Children[0].RecordId}.c`
                        //     : `/${parentCategory.Children[i].Name}/${parentCategory.Children[i].RecordId}.c`;
                        var obj = {
                            image_url: url,
                            name: name,
                            link: link
                        };
                        categoriesList.push(obj);
                    }
                }
            }
        }

        // @ts-ignore
        const chunk = (arr, size) => Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
        categoriesList = chunk(categoriesList, 2);
        return (
            <>
                <div className='container'>
                    <h2 className='category-header'>Shop by category</h2>
                </div>
                <Slider {...settings} className='container category'>
                    {categoriesList.map((card, index) => (
                        <div key={index}>
                            <div className={`image-1`}>
                                <a href={card[0].link}>
                                    <h4 className={`custom-category-name ${card[0].name.toLowerCase().replace(/ /g, '-')}`}>
                                        {card[0].name}
                                    </h4>
                                    <img src={card[0].image_url} width='230' height='180' />
                                </a>
                            </div>
                            {card.length <= 1 ? null : (
                                <div className='image-2'>
                                    <h4 className='custom-category-name'>{card[1].name}</h4>
                                    <a href={card[1].link}>
                                        <img src={card[1].image_url} width='230' height='180' />
                                    </a>
                                </div>
                            )}
                        </div>
                    ))}
                </Slider>
            </>
        );
    }
}

export default ApiTest;
